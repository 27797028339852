<home>
    <article>
        <img src="/articles/images/home1.webp" alt="main-pic" srcset="">
        <h1>ברוכים הבאים ללו-ג'ונג - מרכז לאימון תודעה, איזון והתפתחות אישית בגישה בודהיסטית, יוגית וטנטרית</h1>
        <h3 class="sub-header">
            שלווה, בהירות ואיזון בעולם מודרני, בתרבות מאתגרת ומורכבת
            <br>
        </h3>
        <p class="slogan2">
            בלו-ג'ונג, אנו מביאים חכמה עתיקה אל תוך העולם המודרני: שילוב של תרגולים וגישות מהמזרח וטכניקות ניהול
            מודרניות, כדי
            ליצור איזון, שלווה והגשמה אישית ומקצועית.
            <br>
            אנו עוסקים בפיתוח אישי המבוסס על בהירות פנימית, ריאליזם ופיתוח מיומנויות לחיים מתוך תודעה מאוזנת ורגועה
            בכלים בודהיסטים, יוגיים ועוד.
        </p>
        <div class="super-points">
            <h1>תארו לעצמכם...</h1>
            <ul>
                <li>אימון אישי מועשר ומתוגבר בכלים
                    <a href="/lib/the-three-yanas">מהבודהיזם והבודהיזם הטיבטי</a>
                    ומשולב
                    <a href="/lib/healing">בהילינג</a>
                </li>
                <li> כישורי
                    <a href="/lib/education">חינוך, הוראה</a>

                    <a href="/lib/healing"> והילינג</a>
                    מועצמים על ידי
                    <a href="/lib/%D7%90%D7%9E%D7%A0%D7%95%D7%AA%20%D7%94%D7%A7%D7%A9%D7%91">קשב</a>
                    עם בסיס של
                    <a href="/lib/%D7%9E%D7%99%D7%99%D7%A0%D7%93%D7%A4%D7%95%D7%9C%D7%A0%D7%A1">מיידפולנס</a>
                    עמוק ורחב
                    <a href="/lib/healing">ואמצעים מיומנים</a>

                </li>
                <li>תקשורת-מקרבת עם
                    <a href="/lib/%D7%9E%D7%93%D7%99%D7%98%D7%A6%D7%99%D7%94">בסיס מדיטטיבי רב עצמה</a>
                    שמעצים את
                    <a href="/lib/%D7%90%D7%9E%D7%A0%D7%95%D7%AA%20%D7%94%D7%A7%D7%A9%D7%91">הקשב</a>
                    והבהירות
                </li>
                <li>ניהול ומנהיגות המועצמים על ידי הבהירות הצלולה והשלווה של הדהרמה
                    <a href="/lib/%D7%94%D7%90%D7%98%D7%94-%D7%99%D7%95%D7%92%D7%94">והיוגה</a>
                </li>
                <li>גידול
                    <a href="/lib/%D7%9E%D7%95%D7%A8%D7%9B%D7%91%D7%95%D7%AA-%D7%94%D7%A0%D7%A2%D7%95%D7%A8%D7%99%D7%9D">ילדים ונוער</a>
                    כשלרשותכם
                    <a href="/lib/about-the-tools">כלים</a>
                    שישמרו אתכם חיוביים, קשובים, ורגועים יותר
                </li>
                <li>
                    <a href="/lib/%D7%9E%D7%A2%D7%A8%D7%9B%D7%95%D7%AA-%D7%99%D7%97%D7%A1%D7%99%D7%9D">זוגיות</a>
                    על בסיס צמיחה רוחנית משותפת שהיא יותר אינטימית ורוחנית יותר ממה שהעזתם לחלום עליו
                </li>
                <li>התגברות על בעיות רגשיות,
                    <a href="/lib/habits">התמכרויות, הרגלים רעים</a>
                    ולמעשה הפיכתם לכלי לצמיחה והעצמה עם חכמת
                    <a href="/lib/tantra">הטנטרה</a>

                    ו<a href="/lib/%D7%A4%D7%A1%D7%99%D7%9B%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%AA-%D7%A2%D7%95%D7%9E%D7%A7">פסיכולוגית עומק בודהיסטית</a>

                </li>

            </ul>
        </div>
        <div class="programs">
            <h2>הצטרפו אלינו לסדנאות ותוכניות מיוחדות</h2>
            <ul>
                <li>סדנאות מתקדמות לפיתוח אישי ומקצועי</li>
                <li> ייעוץ ואימון לשיפור איכות החיים והתודעה</li>
                <li>תוכניות מותאמות לארגונים לשיפור ההרמוניה, בריאות העובדים ואיכות העבודה</li>
                <li> ליווי, אימון והדרכה של <a href="/lib/%D7%9E%D7%A2%D7%A8%D7%9B%D7%95%D7%AA-%D7%99%D7%97%D7%A1%D7%99%D7%9D" target="_blank">זוגות, משפחות</a> קבוצות, <a
                        href="/lib/%D7%9E%D7%95%D7%A8%D7%9B%D7%91%D7%95%D7%AA-%D7%94%D7%A0%D7%A2%D7%95%D7%A8%D7%99%D7%9D" target="_blank"> ונוער</a></li>
                <li style="color:var(--special-color)">השבחת מורים, מטפלים, מאמנים ומדריכים</li>
            </ul>
            <p>
                <a href="/plans">ראו תכניות ומחירים</a>
            </p>
        </div>
        <contact-widget/>
        <h2>מי אנחנו</h2>
        <img src={MyPic} class="small-pic" alt="my-pic">

        <p>

            <a href="about-me">
                אבי תשובה
            </a>
            , מייסד לו-ג'ונג, מביא עימו ניסיון רב, של עשרות שנים, בתחומי המדיטציה, הבודהיזם, היוגה והטאואיזם, כמו גם בעולם העסקים
            וההייטק ומציע שיטות מעשיות ומותאמות לצרכים הייחודיים של החיים המודרניים.
            את הפעילות מלווה לעיתים גם
            <a href="https://www.barclinic.co.il/" target="_blank">גדי בר </a>מומחה לרפואה סינית ויפנית, שישלים את מה
            שרלוונטי עבור מי שזקוק לכך.
        <p>
            אגב, פירוש השם
            <b>לו-ג'ונג</b>
            הוא "אימון-התודעה", והוא מתייחס ללימוד מאוד ספציפי בבודהיזם הטיבטי. ולא - לא נלמד את
            הלימוד המסוים הזה במסגרות הרגילות שאנו מציעים, אך ניקח גם משם כלים ועקרונות שימושיים.
        </p>

        <the-process/>

    </article>
    <contact-form if={state.contactFormOpen} closeCB={()=>openContactForm(false)}/>

    <section class="article-carousel">
        <h2>מאמרים ומיני-מאמרים...</h2>
        <recent-articles/>
    </section>
    <contact-widget/>

    <style>
        the-process article {
            max-height: fit-content;
        }

        .article-carousel {
            display: flex;
            flex-direction: column;
            align-items: center;

        }
        .article-carousel h2 {
            align-self: start;
        }
        recent-articles {
            max-width: 60em;
        }

        .english {
            direction: ltr;
            font-size: 1rem;

            transform: rotate(10deg);
            text-align: center;
        }

        .english a {
            font-weight: bold;
            background-color: var(--opaque-background-color);
            border: 4px double var(--accent-color);
            padding: 3px;
            border-radius: 1em;
        }

        .small-pic {
            max-width: 30%;
            float: right;
            margin-left: 1em;
        }

    </style>
    <script>

        import ArticleDisplay from './article-display.riot'
        import TheProcess from './the-process.riot'
        import ContactWidget from '../contact-widget.riot'
        import Recommendations from '../recommendations.riot'
        import RecentArticles from '../recent-articles.riot'
        import MyPic from '../../styles/resources/avi-a.jpg'

        export default {
            components: {
                ArticleDisplay,
                TheProcess,
                ContactWidget,
                Recommendations,
                RecentArticles
            },
            MyPic,
        }
    </script>

</home>